
import {
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
} from "@vue/runtime-core";
import { toRefs } from "vue";
import { fivePointList } from "../../assets/homeData";
export default defineComponent({
  setup () {
    const data = reactive({
      fivePointData: [{
        name: '',
        content: '',
        showImg: '',
      }]
    });
    const selectIndex = ref(0);

    onBeforeMount(() => {
      data.fivePointData = fivePointList;
    });
    return {
      ...toRefs(data),
      selectIndex,
    };
  },
});
