<template>
  <div class="blue-print-wrapper paddingTop40">
    <div class="blue-print">
      <h3 class="text-center">ERP智慧药店蓝图</h3>
      <img src="../../images/img_smart_drugstore.png" />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.blue-print-wrapper {
  background: linear-gradient(135deg, #f9fafd 0%, #eaf0f8 100%);
  padding-bottom: 29px;
  .blue-print {
    width: 1220px;
    margin: 0 auto;
    h3 {
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
    }
    img {
      width: 100%;
      margin-top: -47px;
    }
  }
}
</style>
