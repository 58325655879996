<template>
  <div class="nav relative zIndex2 row-flex items-center">
    <div class="nav-content">
      <div class="logo"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.nav {
  height: 60px;
  width: 100%;

  .nav-content {
    width: 1200px;
    margin: 0 auto;

    .logo {
      width: 68px;
      height: 32px;
      background: url("../../images/logo_shita_n.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  &:hover {
    background: #fff;

    .logo {
      background: url("../../images/logo_shita_h.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
