
import { Options, Vue } from "vue-class-component";
import NavView from "@/views/layout/NavView.vue"; // @ is an alias to /src
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
@Options({
  components: {
    NavView,
  },
  data(){
    return {
      locale:zhCN
    }
  }
})
export default class App extends Vue { }
