export function extractUrlQueryParams(url: string): { [key: string]: string } {
  const queryParamsStart = url.indexOf("?");
  if (queryParamsStart === -1) {
    return {};
  }

  const queryPart = url.slice(queryParamsStart + 1);
  const queryParams = new URLSearchParams(queryPart);

  return Object.fromEntries([...queryParams.entries()]);
}

export function isWechatOrEnterpriseWechatBrowser() {
  try {
    const userAgent = navigator.userAgent.toLowerCase();

    // 判断是否在微信浏览器中打开
    const isWechat = /micromessenger/.test(userAgent);

    // 判断是否在企业微信浏览器中打开
    const isEnterpriseWechat = /wxwork/.test(userAgent);

    return isWechat || isEnterpriseWechat;
  } catch (err) {
    return true;
  }
}
