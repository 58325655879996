<template>
  <div class="compare-erp-wrapper paddingTop40">
    <div class="compare-erp">
      <h3 class="text-center">ERP产品对比</h3>
      <dl class="item text-center">
        <dt>项目</dt>
        <dd>收银系统</dd>
        <dd>药店赋能</dd>
        <dd>网上开店</dd>
        <dd>电子处方</dd>
        <dd>医保服务</dd>
        <dd>远程诊疗</dd>
        <dd>在线采购</dd>
        <dd>电商能力</dd>
        <dd>软件费用</dd>
      </dl>
      <dl class="ours">
        <dt>视塔ERP</dt>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>进销存系统，会员管理等</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>打通问诊屏，助力传统药店升级药诊店</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>集成多渠道电商平台,全网开店</span>
        </dd>
        <dd>
          <img class="marginRight12 marginBot24" src="../../images/ic_contrast_ok.png" />
          <span>承接医院电子处方流转及门店问诊处方,共享千亿增量市场</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>免费接入医保，患者购药无忧</span>
        </dd>
        <dd>
          <img class="marginRight12 marginBot24" src="../../images/ic_contrast_ok.png" />
          <span>连接互联网医院，可实现线上问诊，线下取药O2O模式</span>
        </dd>
        <dd>
          <img class="marginRight12 marginBot24" src="../../images/ic_contrast_ok.png" />
          <span>集采优势，厂家/批发，智能推荐，降低成本,合规溯源</span>
        </dd>
        <dd>
          <img class="marginRight12 marginBot24" src="../../images/ic_contrast_ok.png" />
          <span>十多年行业沉淀，团队经验丰富，专业免费商学院指导培训</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>入驻合作电商平台后免费使用</span>
        </dd>
      </dl>
      <dl class="others">
        <dt>传统软件</dt>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_ok.png"
          /><span>进销存系统，会员管理等</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>无</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>不支持 / 无特殊合作渠道</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>不支持，无处方来源</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>无，另行高价购</span>买
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>不支持</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>不支持</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>无</span>
        </dd>
        <dd>
          <img
            class="marginRight12"
            src="../../images/ic_contrast_worning.png"
          /><span>每年付费</span>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.compare-erp-wrapper {
  padding-bottom: 50px;
  .compare-erp {
    width: 1110px;
    margin: 0 auto;
    h3 {
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 44px;
    }
    dt {
      height: 86px;
      margin: 0;
      border-bottom: 1px dashed;
    }
    dd {
      height: 76px;
      margin: 0;
      font-size: 18px;
      line-height: 25px;
      & + dd {
        border-top: 1px solid rgba(201, 207, 223, 0.3);
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
    .item {
      display: inline-block;
      width: 150px;
      height: 770px;
      background: linear-gradient(270deg, #f9fbff 0%, #f1f5ff 100%);
      border-radius: 12px 0px 0px 12px;
      letter-spacing: 1px;
      padding: 0 8px;
      vertical-align: bottom;
      dt {
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 2px;
        padding: 26px 0;
        border-bottom-color: #c9cfdf;
      }
      dd {
        padding: 26px 0;
        font-weight: 550;
      }
    }
    .ours {
      display: inline-block;
      width: 480px;
      height: 770px;
      background: #fff;
      border-radius: 12px;
      border: 1px solid #aac2ff;
      padding: 0 8px;
      position: relative;
      z-index: 1;
      vertical-align: bottom;
      dt {
        font-size: 28px;
        font-weight: 800;
        line-height: 38px;
        letter-spacing: 2px;
        padding: 24px 30px;
        border-bottom-color: #c9cfdf;
      }
      dd {
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-items: center;
        font-weight: 550;
      }
    }
    .others {
      display: inline-block;
      width: 485px;
      height: 770px;
      background: #f1f5ff;
      border-radius: 12px;
      padding: 0 8px;
      margin-left: -6px;
      position: relative;
      z-index: 0;
      border: 1px solid transparent;
      vertical-align: bottom;
      &:hover {
        z-index: 2;
        border: 1px solid #aac2ff;
      }
      dt {
        color: #7888b0;
        font-size: 24px;
        font-weight: 800;
        line-height: 38px;
        letter-spacing: 2px;
        padding: 32px 30px 16px 30px;
        border-bottom-color: #c9cfdf;
      }
      dd {
        font-size: 16px;
        color: #7888b0;
        padding: 26px 30px;
      }
    }
  }
}
</style>
