<template>
  <div class="hot-line text-center">
    <div><span class="paddingRight12">咨询热线:</span>021-6862 0275</div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.hot-line {
  background: url("../../images/img_banner_consulting_hotline.png") no-repeat;
  background-size: 100% 100%;
  height: 200px;
  font-size: 34px;
  font-weight: 500;
  color: #ffffff;
  line-height: 200px;
}
</style>
