
import { Options, Vue } from "vue-class-component";
// import BannerView from "./BannerView.vue";
import BannerView from "../layout/BannerView.vue";
import CoreFunc from "./CoreFunc.vue";
import FivePoint from "./FivePoint.vue";
import PlayWell from "./PlayWell.vue";
import CompareErp from "./CompareErp.vue";
import BluePrint from "./BluePrint.vue";
import Cooperate from "./Cooperate.vue";
import HotLine from "../layout/HotLine.vue";
import FooterView from "../layout/FooterView.vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
var queryString = window.location.hash.split('?')[1];

console.log(queryString, 'queryString')
var params = new URLSearchParams(queryString);

var code = params.get("code") || ""; // "value1"
@Options({
  components: {
    BannerView,
    CoreFunc,
    FivePoint,
    PlayWell,
    CompareErp,
    BluePrint,
    Cooperate,
    HotLine,
    FooterView,
  },
})
export default class HomeView extends Vue {
  router = useRouter();
  imgBg = require("../../images/img_home_banner.png")
  helpBg = require("../../images/btn_home_help.png")
  ele_home_code = require("../../images/ele_home_code.png")
  ele_get_invitation_code = require("../../images/ele_get_invitation_code.png")
  btn_password_hide_h = require("../../images/btn_password_hide_h.png")
  btn_password_show_h = require("../../images/btn_password_show_h.png")
  code = code
  qrcode = require("../../images/90ad-d5dd7858a2a1.png")
  endDate = 590
  onShow = false
  data() {
    return {
      endDate: 590
    }
  }
  mounted(): void {
    if (!code) return
    this.endDate = 590
    let timer = setInterval(() => {
      console.log('run', this.endDate)
      this.endDate -= 1;
      if (!this.endDate) {
        clearInterval(timer)
      }
    }, 1000)
  }
  copyCode = () => {
    // 创建一个临时的textarea元素
    var textarea = document.createElement('textarea');
    // 将要复制的文本设置为textarea的值
    textarea.value = code;
    // 将元素添加到DOM中
    document.body.appendChild(textarea);
    // 选中textarea的文本
    textarea.select();
    // 复制文本到剪切板
    document.execCommand('copy');
    // 删除临时的textarea元素
    document.body.removeChild(textarea);
    message.success('复制成功')
  }
  getDate = () => {
    const num1 = Math.floor(this.endDate / 60)
    const num2 = this.endDate % 60
    return (num1 < 10 ? '0' + num1 : num1 || '00') + ':' + (num2 < 10 ? '0' + num2 : num2 || '00')
  }
  jumpHelper = () => {
    window.open('https://help-erp.yaofangwang.com/')
  }
  codeHide = () => {
    var replacement = '';
    var length = code.length;
    if (length >= 4 && !this.onShow) {
      var asterisks = '';
      for (var i = 3; i < code.length; i++) {
        asterisks += '*';
      }
      replacement = code.substr(0, 4) + asterisks;
    } else {
      replacement = code;
    }
    return replacement;
  }
  downloadErp = () => {
    const a = document.createElement("a");
    a.target = "downloadErp";
    a.setAttribute("href", `https://download.shitaerp.com/shita/filedownload/视塔ERP安装程序.exe`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
