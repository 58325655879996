interface ListCore {
    name: string;
    bgUrl: string;
    content: string;
    showImg: string;
}
export const imgsList: Array<ListCore> = [
    {
        name: "采购管理",
        bgUrl: require("../images/ele_product_function_blue.png"),
        content: "厂家直采、商业批发、一键入库、药品溯源、采购计划、验收入库",
        showImg: require("../images/metrial/img_purchase.png"),
    },
    {
        name: "库存管理",
        bgUrl: require("../images/ele_product_function_purple.png"),
        content: "商品建档、库存管理、供应商管理、采购退货、退货验收",
        showImg: require("../images/metrial/img_inventory.png"),
    },
    {
        name: "销售管理",
        bgUrl: require("../images/ele_product_function_middle_blue.png"),
        content:
            "线下零售收银、人人都是收银台、销售明细、处方管理、全平台订单管理",
        showImg: require("../images/metrial/img_market.png"),
    },
    {
        name: "医保对接",
        bgUrl: require("../images/ele_product_function_blue_2.png"),
        content:
            "医保设置、读卡结算、结算日志、自动对码、全量日志管理、医保订单追溯",
        showImg: require("../images/metrial/img_health_insurance.png"),
    },
    {
        name: "财务管理",
        bgUrl: require("../images/ele_product_function_blue.png"),
        content:
            "结算管理、财务对账明细、收支明细、月度对账单、付款管理、提成管理",
        showImg: require("../images/metrial/img_finance.png"),
    },
    {
        name: "经营报表",
        bgUrl: require("../images/ele_product_function_purple.png"),
        content:
            "经营看板、销售报表、商品分析、进销存变动表、员工业绩报表、采购报表等",
        showImg: require("../images/metrial/img_procurement.png"),
    },
    {
        name: "GSP规范",
        bgUrl: require("../images/ele_product_function_middle_blue.png"),
        content:
            "首营管理、药品养护、出库复核记录、近效产品催销表、不合格药品管理",
        showImg: require("../images/metrial/img_gsp.png"),
    },
    {
        name: "账号管理",
        bgUrl: require("../images/ele_product_function_blue_2.png"),
        content: "账号和角色管理、店铺图片、库存货位管理、人员管理、系统日志等",
        showImg: require("../images/metrial/img_account_number.png"),
    },
]
type ListPoint = {
    name: string;
    content: string;
    showImg: string;
};
export const fivePointList: Array<ListPoint> = [
    {
        name: "全网销售",
        content:
            "对接各大B2C、O2O医药电商平台，实现一套系统全网开店，销售无盲点，用户全国覆盖，提升订单量",
        showImg: require("../images/metrial/img_whole_network_sales.png"),
    },
    {
        name: "处方流转",
        content:
            "对接互联网医院，医生在线问诊开方，一键审方，线上线下无忧销售处方药",
        showImg: require("../images/metrial/img_prescription.png"),
    },
    {
        name: "智慧集采",
        content:
            "整合药厂及批发企业，聚集全国药店资源低价采购，资质互换、一键入库、票据全链路溯源，确保采购无忧，提升资金周转效率",
        showImg: require("../images/metrial/img_gathering_of_wisdom.png"),
    },
    {
        name: "医保对接",
        content:
            "免费对接全国各省新医保系统，实现医保刷卡结算，并支持系统自动医保对码、数据自动上报、结算日志自动冲正",
        showImg: require("../images/metrial/img_link_to_health_insurance.png"),
    },
    {
        name: "增值服务",
        content:
            "以门店问诊屏为载体，为顾客提供在线诊疗、开方、收银售药一站式服务，提升客流转化率，全方位增强门店服务能力",
        showImg: require("../images/metrial/img_value-added_services.png"),
    },
]
type ListPlay = {
    name: string;
    content: string;
};
export const palyWellList: ListPlay[] = [
    {
        name: "为什么要使用视塔智慧药房ERP？",
        content: "视塔智慧药房ERP是线下药店打开互联网大门的金钥匙，助力传统药店实现数字化转型。使用视塔智慧药房ERP，传统药店升级为网上药店，连接全国顾客；对接互联网医院，合法合规销售处方药；大数据帮助药店老板智能选品，双通道采购扩大货源，质优价廉。"
    },
    {
        name: "使用视塔ERP怎么管理网店？",
        content: "与药房网商城签订入驻协议并注册提交资质，下载安装并注册视塔ERP软件，按照新手指导操作，方便简单。轻松玩转销售、采购、库存管理、经营报表进行网店管理。",
    },
    {
        name: "ERP智慧集采如何实现?",
        content: "商家通过ERP系统组建药店集中采购联合体，根据大数据以合作互利的原则，形成强大的采购需求和议价能力，增加与药品厂家谈判砝码，有效降低采购成本，获得质优价廉的药品，精准低价集采提高资金周转。",
    },
    {
        name: "视塔ERP怎么接收电子处方？",
        content: "ERP对接互联网医院，医院在线给客户开立电子处方，处方回传至驻店执业药师或在线审方平台完成审方后，流转至药店完成处方药销售。",
    },
    {
        name: "不会经营网店怎么办？",
        content: "24小时随时在线学习，每个新商户参加商学院，解决问题，助力商户成长；专业技术团队保证ERP软件的运维，提供技术指导；专人负责商家疑问咨询，确保顺利转型及高效运营。",
    },
];
