import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import PddView from '../views/pdd_auth/HomeView.vue'
import AboutView from '../views/about/AboutView.vue'
import StoreMemberShipRegistration from '../views/mobile/StoreMemberShipRegistration.vue'
import ElectronicInvoice from '../views/mobile/ElectronicInvoice.vue'
import signature from '@/views/signature/signature.vue'
import MarginPicture from '@/views/mobile/MarginPicture.vue'
import UploadYyzz from '@/views/mobile/uploadYyzz.vue'
import UploadYpjy from '@/views/mobile/uploadYpjy.vue'
import UploadSfz from '@/views/mobile/uploadSfz.vue'
import UploadYszg from '@/views/mobile/uploadYszg.vue'
import UploadYsSfz from '@/views/mobile/uploadYsSfz.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pddAuth',
    name: 'pddAuth',
    component: PddView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/electronic_invoice',
    name: 'electronic_invoice',
    component: ElectronicInvoice
  },
  {
    path:'/margin_picture',
    name:'margin_picture',
    component:MarginPicture
  },
  {
    path:'/uploadYyzz',
    name:'uploadYyzz',
    component:UploadYyzz
  },
  {
    path:'/uploadYpjy',
    name:'uploadYpjy',
    component:UploadYpjy
  },
  {
    path:'/uploadSfz',
    name:'uploadSfz',
    component:UploadSfz
  },
  {
    path:'/uploadYsSfz',
    name:'uploadYsSfz',
    component:UploadYsSfz
  },
  {
    path:'/uploadYszg',
    name:'uploadYszg',
    component:UploadYszg
  },
  {
    path: '/store_membership_registration',
    name: 'store_membership_registration',
    component: StoreMemberShipRegistration
  },
  {
    path:'/signature',
    name: 'signature',
    component: signature
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
