import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ant from "ant-design-vue";
import Vant from 'vant';
import "ant-design-vue/dist/antd.css";
import "./style/global.scss";
import 'vant/lib/index.css';



createApp(App).use(store).use(router).use(ant).use(Vant).mount("#app");
