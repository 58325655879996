<template>
  <div class="agree-manage">
    <div class="row-flex row-flex">
      <p class="sign-instruct">签名计算工具</p>
      
      <a-row :gutter="16">
        <a-col :span="12" >
          <a-divider orientation="left">HTTP请求信息</a-divider>
          <a-row :gutter="16" style="display: flex;
    align-items: center;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >路径：</div></a-col>
            <a-col :span="12">
              <a-input  v-model:value="apiPath" placeholder="请输入API路径" />
            </a-col>
          </a-row>
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >参数：</div></a-col>
            <a-col :span="12">
              <a-textarea   v-model:value="bodyJSON" placeholder="请输入JSON格式参数" />
            </a-col>
          </a-row>
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >Host：</div></a-col>
            <a-col :span="12">
              <a-input   v-model:value="host" placeholder="请输入Host" />
            </a-col>
          </a-row>
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >请求方式：</div></a-col>
            <a-col :span="12">
              <a-select
                style="width: 100%;"
                v-model:value="httpMethod"
              >
                <a-select-option value="POST">POST</a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12" >
          <a-divider orientation="left">签名信息</a-divider>
          <a-row :gutter="16" style="display: flex;
    align-items: center;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >AccessKeyId(ak)：</div></a-col>
            <a-col :span="12">
              <a-input   v-model:value="accessKey" placeholder="请输入AccessKeyId" />
            </a-col>
          </a-row>
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >SecretAccessKey(sk)：</div></a-col>
            <a-col :span="12">
              <a-input   v-model:value="secretKey" placeholder="请输入SecretAccessKey" />
            </a-col>
          </a-row>
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >时间戳：</div></a-col>
            <a-col :span="12">
              <a-input   v-model:value="timestamp" placeholder="请输入时间戳或刷新自动填取" />
            </a-col>
            <a-button type="primary" @click="getDateNow"> 刷新</a-button>
          </a-row>
          
          <a-row :gutter="16" style="display: flex;
    align-items: center;margin-top: 12px;">
            <a-col :span="6" style="display: flex;"><div><span style="color: red;margin-right:5px">*</span >nonce：</div></a-col>
            <a-col :span="12">
              <a-input   v-model:value="nonce" placeholder="请输入nonce或刷新自动填取" />
            </a-col>
            <a-button type="primary" @click="generateTwelveDigitRandomNumber"> 刷新</a-button>
          </a-row>
        </a-col>
      </a-row>
      <a-button style="width: 200px;margin-top: 5px;margin: 10px auto" type="primary" @click="postData" :disabled="!canSubmit">一键计算</a-button>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-divider orientation="left">计算结果</a-divider>
          </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="18">
          <div>
            <p>中间结果：待签名字符串</p>
            <div class="bacResult">
              {{signData}}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 12px;">
        <a-col :span="18">
          <div>
            <p>最终结果：签名摘要 signature</p>
            <div class="bacResult">
              {{sign}}
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { message } from "ant-design-vue"
export default {
  name: "signature",
  data() {
    return {
      showMenu: "",
      shopIds: [],
      apiPath:'',
      bodyJSON:"",
      host:'',
      accessKey:'',
      secretKey:'',
      timestamp:'',
      signData:'',
      sign:'',
      nonce:'',
      httpMethod:'POST'
    };
  },
  components: {
    
  },
  watch: {
    
  },
  computed:{
    canSubmit(){
      if(!this.apiPath||!this.bodyJSON||!this.host||!this.accessKey||!this.secretKey||!this.timestamp||!this.nonce||!this.httpMethod){
        return false
      }else{
        return true
      }
    }
  },
  mounted() {
  },
  methods: {
  async postData() {
    const url = 'https://openapi-test.shitaerp.com/open/web/sign/generate';
    const data = {
      accessKey:this.accessKey,
      apiPath:this.apiPath,
      body:this.bodyJSON,
      host:this.host,
      secretKey:this.secretKey,
      timestamp:this.timestamp+'',
      httpMethod:this.httpMethod,
      nonce:this.nonce+'',
    };
    this.sign = this.generateHmacSignature(data,this.secretKey);
    // try {
    //     const response = await fetch(url, {
    //         method: 'POST', 
    //         headers: {
    //             'Content-Type': 'application/json', // 
    //         },
    //         body: JSON.stringify(data), // 将数据对象转换为JSON字符串
    //     });

    //     if (!response.ok) {
    //         throw new Error(`网络请求错误，状态码：${response.status}`);
    //     }
    //     const result = await response.json();
    //     if(result.code == 100&&result.success){
    //       message.success("请求成功！")
    //       this.signData = result.data?.signData||'';
    //       this.sign = result.data?.sign||'';
    //     }else{
    //       this.signData = '';
    //       this.sign = '';
    //       message.warning(result.msg?result.msg:"请求失败！")
    //     }
    // } catch (error) {
    //   message.warning(error.msg?error.msg:"请求失败！")
    // }
    },
  getDateNow(){
    this.timestamp = Date.now()
  },
  generateTwelveDigitRandomNumber() {
    this.nonce =  Math.floor(Math.random() * 1e12) + 1e11;
  },
  generateHmacSignatureHelper(stringToSign, secret) {
    // 将密钥和待签名字符串转换为WordArray格式
    const key = CryptoJS.enc.Utf8.parse(secret);
    const messageTo = CryptoJS.enc.Utf8.parse(stringToSign);
    // 使用HMAC-SHA256算法计算哈希
    const hmac = CryptoJS.HmacSHA256(messageTo, key);
    // 对HMAC结果进行Base64编码
    message.success("计算成功！")
    return CryptoJS.enc.Base64.stringify(hmac);
  },
  generateHmacSignature(data, secretKey) {
    const contentMD5 = this.computeContentMD5(data.body);
    // 构建待签名的字符串
    let stringToSign = `${data.httpMethod.toUpperCase()}/${data.host}${data.apiPath}?accessKey=${data.accessKey}&timestamp=${data.timestamp}&nonce=${data.nonce}&contentMd5=${contentMD5}`;
    this.signData = stringToSign||'';
    // 使用定义的函数生成HMAC-SHA256签名
    return this.generateHmacSignatureHelper(stringToSign, secretKey);
  },
  computeContentMD5(body) {
    return CryptoJS.MD5(body);
  },
  },
  
};
</script>
<style lang="scss" scoped>
.agree-manage {
  color: #333;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top:1px solid #333;
  .row-flex.row-flex {
    margin-left: 4vw;
    padding: 20px 40px 40px;
    display: flex;
    flex-direction: column;
  }
  .flex-div{
    display: flex;
  }
  .flex_dri{
    display: flex;
    flex-direction: row;
    .left_box{
      padding: 20px 0 0px 20px;
      min-height: 270px;
      background-color: #F5F5F5;
      .http_class{

      }
    }
    .right_box{
      margin-left: 20px;
      padding: 20px 0 0px 20px;
      min-height: 270px;
      background-color: #F5F5F5;
    }
    
  }
}
.bacResult{
  background: #f4f4f4;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  overflow-wrap: break-word; 
  word-wrap: break-word;
}
</style>