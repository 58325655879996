<template>
  <div class="cooperate-wrapper paddingTop40">
    <div class="cooperate">
      <h3 class="text-center">合作流程</h3>
      <div class="step">
        <div class="step-pre-three marginBot20">
          <span class="text-center">1、下载体验</span>
          <span class="text-center">2.注册申请、资料邮寄</span>
          <span class="text-center">3.平台审核</span>
        </div>
        <div class="step-suff-three">
          <span class="text-center">6.交易开启</span>
          <span class="text-center">5.开通结算</span>
          <span class="text-center">4.签订协议</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.cooperate-wrapper {
  padding-bottom: 16px;
  .cooperate {
    width: 1060px;
    margin: 0 auto;
    h3 {
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 38px;
    }
    .step {
      span {
        display: inline-block;
        width: 285px;
        height: 110px;
        font-size: 16px;
        line-height: 90px;
        background: url("../../images/ele_home_cooperation_process.png")
          no-repeat;
        background-size: 100% 100%;
        position: relative;
        &:hover {
          color: #0050fc;
        }
      }
      .step-pre-three {
        span {
          &::before {
            content: " ";
            width: 47px;
            height: 30px;
            display: inline-block;
            background: url("../../images/ic_arrows_1.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: -68px;
            bottom: 44px;
          }
          &:nth-child(3n) {
            &::before {
              width: 31px;
              height: 46px;
              background: url("../../images/ic_arrows_2.png") no-repeat;
              background-size: 100% 100%;
              right: 126px;
              bottom: -24px;
            }
          }
          & + span {
            margin-left: 102px;
          }
        }
      }
      .step-suff-three {
        span {
          &::before {
            content: " ";
            width: 47px;
            height: 30px;
            display: inline-block;
            background: url("../../images/ic_arrows_3.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: -68px;
            bottom: 44px;
          }
          &:nth-child(3n) {
            &::before {
              background: none;
            }
          }
          & + span {
            margin-left: 102px;
          }
        }
      }
    }
  }
}
</style>
