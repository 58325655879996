
import { Options, Vue } from "vue-class-component";
// import BannerView from "./BannerView.vue";
import BannerView from "../layout/BannerView.vue";
import CoreFunc from "./CoreFunc.vue";
import FivePoint from "./FivePoint.vue";
import PlayWell from "./PlayWell.vue";
import CompareErp from "./CompareErp.vue";
import BluePrint from "./BluePrint.vue";
import Cooperate from "./Cooperate.vue";
import HotLine from "../layout/HotLine.vue";
import FooterView from "../layout/FooterView.vue";
import { useRouter } from "vue-router";
@Options({
  components: {
    BannerView,
    CoreFunc,
    FivePoint,
    PlayWell,
    CompareErp,
    BluePrint,
    Cooperate,
    HotLine,
    FooterView,
  },
})
export default class HomeView extends Vue {
  router = useRouter();
  imgBg = require("../../images/img_home_banner.png")

  downloadErp = () => {
    const a = document.createElement("a");
    a.target = "downloadErp";
    a.setAttribute("href", `https://download.shitaerp.com/shita/filedownload/视塔ERP安装程序.exe`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
